import { documentToReactComponents } from "@contentful/rich-text-react-renderer";
import { graphql } from "gatsby";
import Image from "gatsby-image";
import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import moment from "moment";
import SessionPreview from "../components/sessionPreview";
import Breadcrumb from "../modules/breadcrumb";
import Layout from "../modules/layout";
import SEO from "../modules/seo";
import { getValue } from "../helper/translation";

const SpeakerDetail = ({ data: { member }, pageContext }) => {
  const {
    name,
    settings,
    breadcrumbs,
    showBreadcrumbNavigation,
    speakerSessions,
    node_locale,
  } = pageContext;
  const { microcopy } = settings;
  const siteSettings = settings;

  const todayDate = moment(new Date()).format("YYYY-MM-DD");
  const futureSessions = [];
  speakerSessions
    .filter(session => session.node_locale === node_locale)
    .map(session => ({
      ...session,
      generalTitle: session.title,
    }))
    .forEach(session => {
      if (moment(session.endTime).isAfter(todayDate)) {
        futureSessions.push(session);
      }
    });

  const onlyUnique = (value, index, self) => {
    return self.indexOf(value) === index;
  };

  const uniqueSessions = futureSessions.filter(onlyUnique);

  return (
    <Layout siteSettings={siteSettings}>
      <SEO
        title={`${siteSettings.siteName} - ${name}`}
        description={siteSettings.siteDescription.siteDescription}
        author={siteSettings.siteAuthor}
      />
      <Breadcrumb
        visible={showBreadcrumbNavigation}
        breadcrumbs={breadcrumbs}
      ></Breadcrumb>
      <Container fluid as="section" className="teamDetail">
        <Row>
          {member.image && (
            <Col sm={3} lg={4}>
              <Image fluid={member.image.fluid} className="photo" />
            </Col>
          )}
          <Col sm={9} lg={8}>
            <h2 className="name">{member.name}</h2>
            <h4 className="position">
              {member && member.organization && member.organization.name}
            </h4>
            <p className="d-none d-md-block biography">
              {member.biography && (
                <span>{documentToReactComponents(member.biography.json)}</span>
              )}
            </p>
            {/* SESSION PREVIEW COMPONENT */}
            <Row className="lgSessions">
              <Col>
                {uniqueSessions.length > 0 && (
                  <h4 className="position mt-3 mb-0">
                    {getValue("labels.joinMeIn", microcopy)}
                  </h4>
                )}
              </Col>
              {uniqueSessions.map(session => {
                const params = { ...session, speakerDetail: true };
                return (
                  <Col xs={12} sm={12} md={12} lg={12} xl={12} className="py-3">
                    <SessionPreview {...params} />
                  </Col>
                );
              })}
            </Row>
          </Col>
          <Col className="d-md-none biography">
            {member.biography && (
              <p>{documentToReactComponents(member.biography.json)}</p>
            )}
            {/* SESSION PREVIEW COMPONENT */}
            <Row className="d-md-none">
              <Col>
                {uniqueSessions.length > 0 && (
                  <h4 className="position mt-3 mb-0">
                    {getValue("labels.joinMeIn", microcopy)}
                  </h4>
                )}
              </Col>
              {uniqueSessions.map(session => {
                const params = { ...session, speakerDetail: true };
                return (
                  <Col xs={12} sm={12} md={12} lg={12} xl={12} className="py-3">
                    <SessionPreview {...params} />
                  </Col>
                );
              })}
            </Row>
          </Col>
        </Row>
      </Container>
    </Layout>
  );
};

export const pageQuery = graphql`
  query($contentful_id: String!, $node_locale: String!) {
    member: contentfulPerson(
      contentful_id: { eq: $contentful_id }
      node_locale: { eq: $node_locale }
    ) {
      id
      contentful_id
      name
      organization {
        name
      }
      image {
        fluid(quality: 100) {
          base64
          sizes
          src
          srcSet
          srcWebp
          aspectRatio
        }
      }
      biography {
        json
        biography
      }
    }
  }
`;

export default SpeakerDetail;
